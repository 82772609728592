import React, {useEffect, useState} from 'react';
import {
    fetchRefereeMatches,
    fetchRefereeStats,
} from "../../../api";
import RefereeStats from "./RefereeStats";
import {Tab, Tabs} from "@mui/material";
import RefereeMatches from "./RefereeMatches";

function Referee(props) {
    const {matchDetailData, i18String} = props;

    const [refereeStatsData, setRefereeStatsData] = useState(null);
    const [refereeMatchesData, setRefereeMatchesData] = useState(null);
    const [tabToggle, setTabToggle] = useState(0);

    useEffect(() => {
        fetchRefereeStats(matchDetailData?.ref?.id, matchDetailData?.seasonId).then(data => {
            setRefereeStatsData(data.data.data);
        });

        fetchRefereeMatches(matchDetailData?.ref?.id, matchDetailData?.seasonId).then(data => {
            setRefereeMatchesData(data.data.data);
        });
    }, [matchDetailData]);
    console.log('refereeMatchesData', refereeMatchesData);

    const refereeTabs = [
        {
            name: `${i18String('Stats')}`, type: 'stats', toggle: 0
        },
        {
            name: `${i18String('Matches')}`, type: 'matches', toggle: 1
        }
    ]

    const clickTabs = (tab) => {
        setTabToggle(tab.toggle);
    }


    return (
        <div className='referee-section'>
            <div className='referee-name-title-row'>
                {matchDetailData?.ref?.name}
            </div>


            <div className='match-details-player-stats-tabs-section' style={{borderBottom: '1px solid #dfdfdf'}}>
                <Tabs
                    variant={"scrollable"}
                    scrollButtons="auto"
                    TabIndicatorProps={{style: {display: "none"}}}
                    value={tabToggle}
                >
                    {
                        refereeTabs.map((tabs, index) => {
                            return (
                                <Tab
                                    key={tabs.type}
                                    label={tabs.name}
                                    onClick={() => clickTabs(tabs)}
                                    sx={{ textTransform: "none" }}
                                    style={{
                                        color: tabToggle === index ? '#3e2d56' : '#485b74'
                                    }}
                                >
                                </Tab>
                            )
                        })
                    }
                </Tabs>
            </div>

            {
                tabToggle === 0 && <RefereeStats refereeStatsData={refereeStatsData}/>
            }

            {
                tabToggle === 1 && <RefereeMatches refereeMatchesData={refereeMatchesData} i18String={i18String}/>
            }
        </div>
    );
}

export default Referee;
