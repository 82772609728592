import React, {useContext, useEffect, useState} from 'react';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TrendMatchesContent from "./TrendMatchesContent";
import './TrendMatches.css'
import {AppContext} from "../../context/AppContext";
import DocumentMeta from "react-document-meta";
import {trendMatchesMeta} from "../../SEO/SeoMetaTags";
import {useNavigate, useParams} from "react-router-dom";
import dayjs from "dayjs";
import TrendMatchesTableTopScreen from "./TrendMatchesTableTopScreen";
import LinearComponent from "../linearComponent/LinearComponent";

function TrendMatches(props) {
    const {lang, i18String} = useContext(AppContext);

    const navigate = useNavigate();
    const params = useParams();

    const [selectedEventType, setSelectedEventType] = useState('');
    const [typeName, setTypeName] = useState('');
    const [statsName, setStatsName] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [selection, setSelection] = useState('all');
    const [currentDate, setCurrentDate] = useState(new Date().toISOString().substr(0, 10));
    const [week, setWeek] = useState([
        {day: new Date().toISOString().substr(0, 10),},
        {day: dayjs(currentDate).add(1, 'days').format('YYYY-MM-DD')},
        {day: dayjs(currentDate).add(2, 'days').format('YYYY-MM-DD')},
        {day: dayjs(currentDate).add(3, 'days').format('YYYY-MM-DD')},
    ]);

    const selectionOption = [
        {
            name: `${i18String('All')}`, key: 'all',
        },
        {
            name: `${i18String('Top 10')}`, key: 'top',
        },
        {
            name: `${i18String('Top 50')}`, key: 'priority',
        }
    ]

    const eventTypes = [
        {
            typeName: 'goal-stats',
            index: 0,
            tabs: {
                name: `${i18String('Goals')}`,
                titles: [
                    {
                        "type": "Over_2_5_Goals",
                        "odd": `${i18String('Over 2.5')}`,
                        "index": "goal25"
                    },
                    {
                        "type": "Over_1_5_Goals",
                        "odd": `${i18String('Over 1.5')}`,
                        "index": "goal15"
                    },
                    {
                        "type": "Over_0_5_Goals",
                        "odd": `${i18String('Over 0.5')}`,
                        "index": "goal05"
                    },
                    {
                        "type": "Over_3_5_Goals",
                        "odd": `${i18String('Over 3.5')}`,
                        "index": "goal35"
                    },
                    {
                        "type": "Over_4_5_Goals",
                        "odd": `${i18String('Over 4.5')}`,
                        "index": "goal45"
                    },
                    {
                        "type": "BTTS",
                        "odd": `${i18String('BTTS')}`,
                        "index": "btts"
                    },
                    {
                        "type": "Over_0_5_Goals_1H",
                        "odd": `${i18String('Over 0.5 1H')}`,
                        "index": "goal051h"
                    },
                    {
                        "type": "Over_1_5_Goals_1H",
                        "odd": `${i18String('Over 1.5 1H')}`,
                        "index": "goal151h"
                    },
                    {
                        "type": "Over_0_5_Goals_2H",
                        "odd": `${i18String('Over 0.5 2H')}`,
                        "index": "goal052h"
                    },
                    {
                        "type": "Over_1_5_Goals_2H",
                        "odd": `${i18String('Over 1.5 2H')}`,
                        "index": "goal152h"
                    },
                ]
            }
        },
        {
            typeName: 'corner-stats',
            index: 1,
            tabs: {
                name: `${i18String('Corners')}`,
                titles: [
                    {
                        "type": "Over_9_5_Corners",
                        "odd": `${i18String('Over 9.5')}`,
                        "index": "over95"
                    },
                    {
                        "type": "Over_8_5_Corners",
                        "odd": `${i18String('Over 8.5')}`,
                        "index": "over85"
                    },
                    {
                        "type": "Over_7_5_Corners",
                        "odd": `${i18String('Over 7.5')}`,
                        "index": "over75"
                    },
                    {
                        "type": "Over_6_5_Corners",
                        "odd": `${i18String('Over 6.5')}`,
                        "index": "over65"
                    },
                    {
                        "type": "Over_10_5_Corners",
                        "odd": `${i18String('Over 10.5')}`,
                        "index": "over105"
                    },
                    {
                        "type": "Over_11_5_Corners",
                        "odd": `${i18String('Over 11.5')}`,
                        "index": "over115"
                    },
                ]
            }
        },
        {
            typeName: 'card-stats',
            index: 2,
            tabs: {
                name: `${i18String('Cards')}`,
                titles: [
                    {
                        "type": "Over_2_5_Cards",
                        "odd": `${i18String('Over 2.5')}`,
                        "index": "over25"
                    },
                    {
                        "type": "Over_3_5_Cards",
                        "odd": `${i18String('Over 3.5')}`,
                        "index": "over35"
                    },
                    {
                        "type": "Over_4_5_Cards",
                        "odd": `${i18String('Over 4.5')}`,
                        "index": "over45"
                    },
                    {
                        "type": "Over_5_5_Cards",
                        "odd": `${i18String('Over 5.5')}`,
                        "index": "over55"
                    },
                    {
                        "type": "Over_6_5_Cards",
                        "odd": `${i18String('Over 6.5')}`,
                        "index": "over65"
                    },
                ]
            }
        },
    ]

    useEffect(() => {
        if (params.stats && params.type) {
            function capitalizeFirstLetter(str) {
                return str.replace(/\b\w+\b/g, function (word) {
                    const ff = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
                    if (ff.find(item => word[0] === item)) {
                        return word.substring(0, 1) + word.charAt(1).toUpperCase() + word.substring(2);
                    } else {
                        return word.charAt(0).toUpperCase() + word.slice(1);
                    }
                });
            }

            setSelectedEventType(params.stats.toLowerCase());
            setSelectedType(capitalizeFirstLetter(params.type).replace(/-/g, '_'));

        } else {
            setSelectedEventType("goal-stats");
            setSelectedType('Over_2_5_Goals');
        }
    }, []);
    console.log('params.stats', params.stats);
    console.log('params.type', params.type);

    useEffect(() => {
        if (!params.stats && !params.type) {
            setTypeName(i18String('Goals'));
            setStatsName(i18String('Over 2.5'));
        } else {
            setTypeName(params.stats);
            setStatsName(params.type);
            /*const setTypeAndStatsFunc = eventTypes.map((event) => {
                if (event.typeName === params.stats) {
                    setTypeName(event.tabs.name);
                    return event.tabs.titles.map((item) => {
                        const ff = item.type.replace(/_/g, '-').toLowerCase();
                        if (ff === params.type) {
                            setStatsName(item.odd);
                        }
                    })
                }
            });*/
        }
    },[lang, params.stats, params.type]);

    useEffect(() => {
        navigate(`/trend-matches/${selectedEventType.replace(/_/g, '-').toLowerCase()}/${selectedType.replace(/_/g, '-').toLowerCase()}`);
    }, [selectedType, selectedEventType]);

    const onClickEventType = (type) => {
        setSelectedEventType(type.typeName);
        setTypeName(type.tabs.name);

        const eventType = eventTypes.find((et) => et.typeName === type.typeName);
        setSelectedType(eventType.tabs.titles[0].type);
    }

    const onClickType = (tabType) => {
        setSelectedType(tabType.type);
        setStatsName(tabType.odd);
    }

    const eventTypeIndex = eventTypes.findIndex((et) => et.typeName === selectedEventType)
    const eventType = eventTypes[eventTypeIndex];
    const subTabIndex = eventType?.tabs?.titles.findIndex((st) => st.type === selectedType);

    const activeDayStyle = (day) => {
        if (currentDate === day.day) {
            return 'active-week-day'
        }
    }

    //Week Days Format
    const clickDays = async (day) => {
        await setCurrentDate(day)
    }

    const format = (day) => {
        return dayjs(day).format('ddd')
    }
    const formatDDDForMobile = (day) => {
        return dayjs(day).format('ddd')
    }

    const format2 = (day) => {
        return dayjs(day).format('DD')
    }

    const daysFormatFunc = (date) => {
        const currentDate = new Date().toISOString().substr(0, 10);
        const tomorrow = dayjs(currentDate).add(1, 'days').format('YYYY-MM-DD');
        if (date.day === currentDate) {
            return (
                <div>
                    <span className='stats-date-day-name'>{i18String('Today')}</span>
                </div>
            )
        } else if (date.day === tomorrow) {
            return (
                <div>
                    <span className='stats-date-day-name'>{i18String('Tomorrow')}</span>
                </div>
            )
        } else {
            return (
                <div>
                    <span>{format2(date.day)} </span>
                    <span className='stats-date-day-name stats-date-margin-left'>{formatDDDForMobile(date.day)}</span>
                </div>
            )
        }
    }

    const activeStatButton = (item) => {
        if (item.key === selection) {
            return {
                background: 'var(--H3Grey05)',
                color: 'var(--H3Dark0)',
            }
        } else {
            return {}
        }
    }

    return (
        <DocumentMeta {...trendMatchesMeta(typeName, statsName)[lang]}>
            <div className="const-width best-teams-section  const-width-content">
                <div style={{padding: '5px'}}>
                    <LinearComponent />
                </div>

                {/*<TrendMatchesTableTopScreen statsName={statsName} typeName={typeName}/>*/}

                <div className='trend-matches-dates-container'>
                    <div>
                        <Tabs
                            className="best-teams-top-tabs-container"
                            value={eventTypeIndex}
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: 'var(--H3Dark1)'
                                }
                            }}
                        >
                            {
                                eventTypes.map((statsType) => {
                                    return (
                                        <Tab
                                            key={statsType.typeName}
                                            onClick={() => onClickEventType(statsType)}
                                            className="best-teams-top-tabs"
                                            value={statsType.index}
                                            label={statsType.tabs.name}
                                        />
                                    )
                                })
                            }
                        </Tabs>
                    </div>

                    <div className='filter-selection-top-and-priority-container'>
                        <div className="stats-button-container" style={{border: '1px solid var(--H3MidGrey1)'}}>
                            {
                                selectionOption.map((item) => {
                                    return (
                                        <div
                                            style={activeStatButton(item)}
                                            className="stat-button selection-text"
                                            onClick={() => setSelection(item.key)}
                                            key={item.key}
                                        >
                                            {item.name}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className="trend-matches-week-days-section">
                        {
                            week.map((day) => {
                                return (
                                    <div key={day.day} className="week-days-container">
                                        <div key={day.day} className="week-days-container">
                                            <div onClick={() => clickDays(day.day)}
                                                 className={`${'dates'} ${activeDayStyle(day)}`}>
                                                {daysFormatFunc(day)}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className="best-teams-bottom-tabs-section">
                    <Tabs
                        className="best-teams-bottom-tabs-container"
                        variant="scrollable"
                        scrollButtons="auto"
                        value={subTabIndex}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: 'var(--H3Dark1)'
                            }
                        }}
                    >
                        {
                            eventType?.tabs?.titles?.map((tabTitle, index) => {
                                return (<Tab
                                    key={tabTitle.type}
                                    className="best-teams-bottom-tabs"
                                    onClick={() => onClickType(tabTitle)}
                                    value={index}
                                    label={tabTitle.odd}
                                />)
                            })
                        }
                    </Tabs>

                    <div className="trend-matches-mobile-week-days-section">
                        {
                            week.map((day) => {
                                return (
                                    <div key={day.day} className="week-days-container">
                                        <div onClick={() => clickDays(day.day)}
                                             className={`${'dates'} ${activeDayStyle(day)}`}>
                                            {/*<span>{format2(day.day)}</span>
                                            <span className='stats-date-day-name'>{format(day.day)}</span>*/}
                                            {daysFormatFunc(day)}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
                <TrendMatchesContent currentDate={currentDate} key={selectedType} type={selectedType} selection={selection} />
            </div>
        </DocumentMeta>
    );
}

export default TrendMatches;
