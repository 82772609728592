import React, {useContext} from 'react';
import dayjs from "dayjs";
import {AppContext} from "../../../context/AppContext";
import yCard from "../../../assets/icons/mackolik-ycard.gif";
import rCard from "../../../assets/icons/mackolik-rcard.gif";

function RefereeMatches(props) {
    const {timeZone} = useContext(AppContext);

    const {refereeMatchesData, i18String} = props;

    const matchDateTime = (value) => {
        return dayjs(value).tz(timeZone).format("DD/MM/YY")
    }

    return (
        <div>
            {/*MATCHES HEADER*/}
            <div className='referee-matches-row-container'>
                <div></div>
                <div></div>
                <div className='referee-match-header-cell'>{i18String('Y.C.')}</div>
                <div className='referee-match-header-cell'>{i18String('R.C.')}</div>
                <div className='referee-match-header-cell'>{i18String('Foul')}</div>
                <div className='referee-match-header-cell'>{i18String('Pen')}</div>
                <div className='referee-match-header-cell'>{i18String('FT')}</div>
            </div>
            {
                refereeMatchesData && refereeMatchesData.map(match => {
                    return (
                        <div>
                            {/*MATCHES CONTENT*/}
                            <div className='referee-matches-row-container'>
                                <div>
                                    <span className='referee-match-date'>{matchDateTime(match.dateTimeUtc)}</span>
                                </div>

                                <div className='referee-match-teams'>
                                    <span className='referee-match-team-names'>{match.homeTeam}</span>
                                    <span className='referee-match-team-names'>{match.awayTeam}</span>
                                </div>

                                <div className='referee-match-cell-flex'>
                                    {
                                        match.hyellow + match.ayellow > 0 &&
                                            <img style={{width: '10px', height: '13px', marginRight: '5px'}} src={yCard} alt="Yellow Card"/>
                                    }
                                    <div className='referee-match-cell'>
                                        <span>{match.hyellow}</span>
                                        <span>{match.ayellow}</span>
                                    </div>
                                </div>

                                <div className='referee-match-cell-flex'>
                                    {
                                        match.hred + match.ared > 0 &&
                                        <img style={{width: '10px', height: '13px', marginRight: '5px'}} src={rCard} alt="Red Card"/>
                                    }
                                    <div className='referee-match-cell'>
                                        <span>{match.hred}</span>
                                        <span>{match.ared}</span>
                                    </div>
                                </div>

                                <div className='referee-match-cell'>
                                    <span>{match.hfoul}</span>
                                    <span>{match.afoul}</span>
                                </div>

                                <div className='referee-match-cell'>
                                    <span>{match.hpen}</span>
                                    <span>{match.apen}</span>
                                </div>

                                <div className='referee-match-cell'>
                                    <span>{match.hscore}</span>
                                    <span>{match.ascore}</span>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}

export default RefereeMatches;
