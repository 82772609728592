import React from 'react';

function RefereeStats(props) {
    const  {refereeStatsData} = props;

    return (
        <div>
            {
                refereeStatsData && refereeStatsData.map(data => {
                    return (
                        <div>
                            <div className='referee-stats-section-row'>
                                {data.section}
                            </div>
                            {
                                data.stats.map(stat => {
                                    return(
                                        <div className='referee-stat-row-container'>
                                            <span className='referee-info-text'>{stat.name}</span>
                                            <span className='referee-info-text'>{stat.valStr}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    );
}

export default RefereeStats;
